@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Circular;
  src: url('./assets/fonts/circular-std-medium.otf');
}

@font-face {
  font-family: Book;
  src: url('./assets/fonts/circular-book.otf');
}

@font-face {
  font-family: Minusio;
  src: url('./assets/fonts/minusio.ttf');
}

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    font-family: Cairo, ui-serif;
  }
}

@layer utilities {
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

body {
  @apply text-sm h-screen pb-safe overflow-x-hidden;
}

/*
 * Loading Icon Inside Buttons
 */
.icon-spin {
  -webkit-animation: icon-spin 1s infinite linear;
  animation: icon-spin 1s infinite linear;
  display: inline-block;
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}