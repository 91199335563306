.progress-shadow {
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.25);
}

@media only screen and (min-height: 1051px) {
    .cheetah-img {
        bottom: 0
    }

    .fire-img {
        bottom: 0;
        transform: translate(-50%, 0%);
    }
}

@media only screen and (max-height: 1050px) {
    .cheetah-img {
        top: 50%;
    }

    .fire-img {
        top: 50%;
        transform: translate(-50%, 340px);
    }
}

:root {
    --color-primary: #000000FF;
    --color-secondary: #000000BF;
    --color-tertiary: #00000080;
    --color-quaternary: #00000040;
    --color-quinary: #0000001A;
}

.text_shadows {
    text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary), 12px 12px 0 var(--color-quinary);
    color: var(--color-primary);
    animation: shadows 1.2s ease-in infinite, move 1.2s ease-in infinite;
}

@keyframes shadows {
    0% {
        text-shadow: none;
    }

    10% {
        text-shadow: 3px 3px 0 var(--color-secondary);
    }

    20% {
        text-shadow: 3px 3px 0 var(--color-secondary),
            6px 6px 0 var(--color-tertiary);
    }

    30% {
        text-shadow: 3px 3px 0 var(--color-secondary),
            6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
    }

    40% {
        text-shadow: 3px 3px 0 var(--color-secondary),
            6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
            12px 12px 0 var(--color-quinary);
    }

    50% {
        text-shadow: 3px 3px 0 var(--color-secondary),
            6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
            12px 12px 0 var(--color-quinary);
    }

    60% {
        text-shadow: 3px 3px 0 var(--color-secondary),
            6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
            12px 12px 0 var(--color-quinary);
    }

    70% {
        text-shadow: 3px 3px 0 var(--color-secondary),
            6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
    }

    80% {
        text-shadow: 3px 3px 0 var(--color-secondary),
            6px 6px 0 var(--color-tertiary);
    }

    90% {
        text-shadow: 3px 3px 0 var(--color-secondary);
    }

    100% {
        text-shadow: none;
    }
}

@keyframes move {
    0% {
        transform: translate(0px, 0px);
    }

    40% {
        transform: translate(-12px, -12px);
    }

    50% {
        transform: translate(-12px, -12px);
    }

    60% {
        transform: translate(-12px, -12px);
    }

    100% {
        transform: translate(0px, 0px);
    }
}